import * as moment from "moment/moment";
export default {
    required: value => (typeof value == 'number' || !!value) || 'This field is required',
    email: value => !value || (!!value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || 'Invalid email address',
    password: value => !value || /(?=^.{10,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value) || 'The password must be at least 10 characters containing digit, uppercase, lowercase or special chars',
    min: (value, min) => !value || value.length >= min || 'At least ' + min + ' attenders',
    min_value: (value, min) => !value || value >= min || 'The field must be at least ' + min,
    numeric: value => !value || /^\d+$/.test(value) || 'The field must be a number',
    max: (value, max) => !value || value.length <= max || 'Exceeded the room capacity',
    phone: value => !value || /^[0-9\s\-()+]{8,15}$/.test(value) || 'The length of phone number is between 8 and 15 characters only with digits, space or hyphen and braces',
    future: (after, dateTime = null) => {
        return !after || !dateTime || moment(after).isBefore(dateTime) || 'Invalid date time';
    }
};
<template>
  <v-container>
    <login-form/>
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm";
export default {
  components: {LoginForm}
}
</script>
<template>
  <v-card :flat="$vuetify.breakpoint.xs" :class="{'px-2': !$vuetify.breakpoint.xs}" width="450" class="mx-auto py-3">
    <v-progress-linear
        :active="submitting"
        :indeterminate="submitting"
        absolute
        top
        color="primary lighten-1"
    ></v-progress-linear>
    <v-card-title>
      <v-row>
        <v-col>
          <v-img :src="logo" max-width="120" class="mx-auto"></v-img>
        </v-col>
      </v-row>

    </v-card-title>
    <v-card-text>
      <v-form
          ref="login"
          v-model="valid"
      >
        <p v-if="error" class="red--text font-weight-bold">{{ error }}</p>
        <v-text-field
            label="E-Mail Address"
            outlined
            v-model="form.email"
            :rules="[Rule.required, Rule.email]"
        ></v-text-field>

        <v-text-field
            :type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            outlined
            label="Password"
            :rules="[Rule.required]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShowPassword"
        ></v-text-field>
      </v-form>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          tile
          color="info"
          @click.stop="onSubmit"
          :disabled="!valid || submitting"
      >
        Sign in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { token } from '@/api/auth';
import Rule from '@/rules/index';
import logo from '@/assets/transformative.png';
import store from '@/store'

export default {
  name: 'LoginForm',

  data: () => ({
    showPassword: false,
    valid: true,
    submitting: false,
    form: {},
    lazy: false,
    error: null,
    logo,
    Rule
  }),

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    onSubmit(){
      this.submitting = true;
      this.error = null;

      token(this.form).then((response) => {
        switch (response.status) {
          case 200: {
            localStorage.setItem('token', response.data.data.token);
          //  store.dispatch('setToken', response.data.data.token);
            this.$router.push({name: 'Booking'});
            break;
          }
          case 422: {
            this.error = response.data.errors.email[0];
            break;
          }
          default: {
            this.error = 'Unknown error';
            break;
          }
        }
      }).finally(() => {
        this.submitting = false;
      });
    }
  },
  mounted() {

  }
}
</script>